import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <h3 {...{
      "id": "create-an-organization"
    }}>{`Create an Organization`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Sign up at `}<a parentName="p" {...{
            "href": "https://ghostport.app"
          }}>{`ghostport.app`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click "Create Organization", or open an existing organization.`}</p>
      </li>
    </ul>
    <p><img alt="Create Organization" src={require("../public/getting-started/1-create-organization.png")} /></p>
    <h3 {...{
      "id": "create-a-project"
    }}>{`Create a Project`}</h3>
    <ul>
      <li parentName="ul">{`Click "Create Project", or open an existing project.`}</li>
    </ul>
    <p><img alt="Create Project" src={require("../public/getting-started/2-create-project.png")} /></p>
    <h3 {...{
      "id": "create-an-environment"
    }}>{`Create an Environment`}</h3>
    <ul>
      <li parentName="ul">{`In the sidebar, expand "Flags" and click "Create Environment"`}</li>
    </ul>
    <p><img alt="Create Environment" src={require("../public/getting-started/3-create-environment.png")} /></p>
    <h3 {...{
      "id": "get-a-token"
    }}>{`Get a Token`}</h3>
    <ul>
      <li parentName="ul">{`In the sidebar, expand "Tokens" and select the name of the recently-created environment`}</li>
    </ul>
    <p><img alt="Create Token" src={require("../public/getting-started/4-create-token.png")} /></p>
    <h3 {...{
      "id": "register-a-flag"
    }}>{`Register a Flag`}</h3>
    <ul>
      <li parentName="ul">{`In the sidebar, expand "Flags" and select the name of the recently-created environment`}</li>
      <li parentName="ul">{`Click "Create Flag", or the + in the bottom right corner`}</li>
    </ul>
    <p><img alt="Create Flag" src={require("../public/getting-started/5-create-flag.png")} /></p>
    <h3 {...{
      "id": "use-a-technology-specific-sdk"
    }}>{`Use a Technology-Specific SDK`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/technologies/react"
        }}>{`React`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/technologies/python"
        }}>{`Python`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      